import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
} from "@mui/material";
import CompanyModal from "./CompanyModal";
import { AuthContext } from "../App";
import axios from "axios";

function createData(
  cname,
  cperson,
  ccontact,
  cemail,
  cstatus,
  cstatusnumber,
  clastcontacted,
  csocials,
  ccontactedat,
  cnotes,
  cpage,
  cid
) {
  return {
    name: cname,
    person: cperson,
    email: cemail,
    contact: ccontact,
    status: cstatus,
    statusNumber: cstatusnumber,
    last_contacted: clastcontacted,
    socials: csocials,
    contacted_at: ccontactedat,
    notes: cnotes,
    page: cpage,
    id: cid,
  };
}

const contact_status = ["Pending", "In Progress", "Accepted", "Rejected"];

const Companies = () => {
  const [rows, setRows] = useState([]);
  const [modal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [loading, setLoading] = useState(true);
  // const [err, setErr] = useState({
  //   open: false,
  //   message: "",
  // });
  const { state } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get("https://backend.marketing.mlsctiet.com/api/companies/get", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          setLoading(false);
          setRows([]);
          res.data.companies.map((company) => {
            let lastContact = new Date(company.last_contacted_at);
            let contacted = new Date(company.contacted_at);
            setRows((prev) => [
              ...prev,
              createData(
                company.company_name,
                company.poc_name,
                company.mob_no_poc,
                company.email_poc,
                contact_status[company.contact_status - 1],
                company.contact_status,
                lastContact.toLocaleDateString("en-US"),
                company.social_media_contacts,
                contacted.toLocaleDateString("en-US"),
                company.notes,
                company.website,
                company.id
              ),
            ]);
          });
        } else {
          // setErr({
          //   open: true,
          //   message: "Something went wrong",
          // });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        // setErr({
        //   open: true,
        //   message: "Something went wrong",
        // });
        setLoading(false);
      });
  }, [state.token]);

  const columns = [
    { id: 1, headerName: "Name", minWidth: 200, for: "name" },
    { id: 2, headerName: "Person", minWidth: 200, for: "person" },
    { id: 4, headerName: "Contact", minWidth: 200, for: "contact" },
    { id: 3, headerName: "Email", minWidth: 200, for: "email" },
    {
      id: 6,
      headerName: "Last Contacted",
      minWidth: 200,
      for: "last_contacted",
    },
    { id: 5, headerName: "Status", minWidth: 200, for: "status" },
    { id: 9, headerName: "Notes", minWidth: 200, for: "notes" },
    { id: 8, headerName: "Contacted At", minWidth: 200, for: "contacted_at" },
    { id: 7, headerName: "Socials", minWidth: 200, for: "socials" },
    { id: 10, headerName: "Page", minWidth: 200, for: "page" },
  ];

  const openModal = (data) => {
    setOpenModal(true);
    setModalData(data);
  };

  function closeModal() {
    setOpenModal(false);
  }

  return (
    <Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.id}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.headerName}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <CircularProgress sx={{ ml: 1 }} color="primary" />}
              {rows.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={Math.floor(Math.random() * 87368) + 1}
                    style={{ cursor: "pointer" }}
                    onClick={() => openModal(row)}
                  >
                    {columns.map((column) => {
                      return (
                        <TableCell key={column.id}>{row[column.for]}</TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <CompanyModal
        open={modal}
        handleClose={closeModal}
        data={modalData}
        changeData={setModalData}
      />
    </Box>
  );
};

export default Companies;
