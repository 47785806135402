import { Box, List, ListItem, Modal, Typography, Button, Snackbar, Alert } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./styles/Home.module.css";

const CompanyModal = (props) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    Width: 500,
    minWidth: 350,
    bgcolor: "background.paper",
    borderRadius: "12px",
    boxShadow: 24,
    p: 4,
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const emailValid = props.data.email && isValidEmail(props.data.email);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDisabledClick = () => {
    if (!emailValid) {
      setSnackbarOpen(true);
    }
  };

  return (
    <Box>
      <Modal
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.modal}>
          <Typography
            id="modal-modal-title"
            align="center"
            variant="h4"
            component="h2"
          >
            {props.data.name}
          </Typography>
          <List>
            <ListItem>Person: {props.data.person}</ListItem>
            <ListItem>Status: {props.data.status}</ListItem>
            <ListItem>Email: {props.data.email || "N/A"}</ListItem>
            <ListItem>Phone: {props.data.contact}</ListItem>
            <ListItem>Contacted At: {props.data.last_contacted}</ListItem>
            <ListItem>Last Contacted: {props.data.contacted_at}</ListItem>
            <ListItem>Notes: {props.data.notes}</ListItem>
            <ListItem>Page: {props.data.page}</ListItem>
            <ListItem>Socials: {props.data.socials}</ListItem>
          </List>
          <Button variant="outlined" color="primary">
            <Link to={`company/${props.data.id}`} state={{ data: props.data }}>
              Edit
            </Link>
          </Button>
          <Box
            component="div"
            onClick={handleDisabledClick} // Handles click even when button is disabled
            style={{ display: "inline-block", marginLeft: 80 }}
          >
            <Button
              variant="outlined"
              color="primary"
              disabled={!emailValid}
            >
              <Link to={`mailto/${props.data.id}`} state={{ data: props.data }}>
                Send Mail
              </Link>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
          Please enter a valid email of the company.
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompanyModal;
