import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import {
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
} from "@mui/material";
import { AuthContext } from "../App";
import axios from "axios";

function createData(
  id,
  scheduledTime,
  toEmail,
  attachment,
  ccList,
  status
) {
  return {
    id,
    scheduledTime,
    toEmail,
    attachment,
    ccList,
    status
  };
}

const ScheduledCompanies = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const { state } = useContext(AuthContext);

  useEffect(() => {
    axios
      .get("https://backend.marketing.mlsctiet.com/api/companies/get_scheduled_mail", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          setLoading(false);
          setRows([]);
          res.data.forEach((item) => {
            setRows((prev) => [
              ...prev,
              createData(
                item.id,
                new Date(item.scheduled_time).toLocaleString("en-US"),
                item.to_email,
                item.attachment,
                item.cc_list.join(", "),
                item.status
              ),
            ]);
          });
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }, [state.token]);

  const handleDelete = (id) => {
    axios
      .delete(`https://backend.marketing.mlsctiet.com/api/companies/delete_mail/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          setRows((prev) => prev.filter((row) => row.id !== id));
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const columns = [
    { id: "scheduledTime", headerName: "Scheduled Time", minWidth: 200 },
    { id: "toEmail", headerName: "To Email", minWidth: 200 },
    { id: "attachment", headerName: "Attachment", minWidth: 150 },
    { id: "ccList", headerName: "CC List", minWidth: 300 },
    { id: "status", headerName: "Status", minWidth: 150 },
    { id: "actions", headerName: "Actions", minWidth: 150 },
  ];

  const getStatusStyle = (status) => {
    return {
      color: status === 'pending' ? '#FF9900' : 'inherit',
      padding: '6px 12px',
      borderRadius: '4px',
      display: 'inline-block'
    };
  };

  const deleteButtonStyle = {
    backgroundColor: '#FF9900',
    color: 'black'
  };

  return (
    <Box>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <TableContainer sx={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell key={column.id} style={{ minWidth: column.minWidth }}>
                    {column.headerName}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && <CircularProgress sx={{ ml: 1 }} color="primary" />}
              {rows.map((row) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => (
                    <TableCell key={column.id}>
                      {column.id === 'status' ? (
                        <span style={getStatusStyle(row[column.id])}>
                          {row[column.id]}
                        </span>
                      ) : column.id === 'actions' ? (
                        <Button
                          variant="contained"
                          style={deleteButtonStyle}
                          onClick={() => handleDelete(row.id)}
                        >
                          Delete
                        </Button>
                      ) : (
                        row[column.id]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default ScheduledCompanies;