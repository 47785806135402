import Navbar from "./Navbar";
import styles from "./styles/Home.module.css";
import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import ScheduledCompanies from "./scheduledcompanies";
import { Box, Container } from "@mui/system";
import { AuthContext } from "../App";

const Scheduled = (props) => {
  const { state } = useContext(AuthContext);

  return (
    <Box sx={{ overflow: "hidden" }}>
      <Container>
        <Navbar abbvr="Scheduled" name="HUB" />
        <div className={styles.companyTitles}>
          <div>
            My <span className="yellow-color">Scheduled Companies</span>
          </div>
          <div>
          </div>
        </div>
        <div className={styles.companyTable}>
          <ScheduledCompanies />
        </div>
      </Container>
    </Box>
  );
};

export default Scheduled;
