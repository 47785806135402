import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, FormControl, InputLabel, Select, Snackbar, Alert, Hidden } from "@mui/material";
import Navbar from './Navbar';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AuthContext } from "../App";
import { useLocation } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { format } from 'date-fns-tz';

export default function Email() {
  const [emailData, setEmailData] = useState({
    subject: '',
    coordinators: '',
    body: `<p><span style="font-size:12pt;font-family:Arial,sans-serif;">Respected Sir/Ma&apos;am,</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">We are dedicated to empowering students through technology and innovation at&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Microsoft Learn Student Chapter (MLSC)</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, Thapar Institute of Engineering and Technology. Our flagship event,&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MAKEATHON</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, has established itself as one of North India&rsquo;s premier 24-hour hackathons, known for its creativity and impact. Following the tremendous success of&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MAKEATHON 4.0</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, the first&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MLH-sponsored hackathon</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;at TIET, we are eager to take the experience to new heights with&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MAKEATHON 7</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">.</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">We are excited to invite&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">[COMPANY NAME]</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;to sponsor&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MAKEATHON 7</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">. With over&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">18,000 social media followers</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;(including&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">4.5k on Facebook</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">,&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">4.1k on Instagram</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">,&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">5k on LinkedIn,</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;and&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">4k on Discord</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">), and more than&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">1,800 anticipated participants</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, MLSC offers unparalleled brand exposure and engagement opportunities.</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">We invite you to establish a strategic partnership with us by recognizing&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">[COMPANY NAME]</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;as a leader in&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">[About]</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">. Our esteemed organization will furnish your enterprise with</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;targeted market exposure, and enhanced brand visibility.</span></strong></p>
<p><span style="color:#0e101a;font-size:12pt;font-family:Arial,sans-serif;">Our detailed</span><strong><span style="color:#0e101a;font-size:12pt;font-family:Arial,sans-serif;">&nbsp;sponsorship proposal</span></strong><span style="color:#0e101a;font-size:12pt;font-family:Arial,sans-serif;">&nbsp;is enclosed for your consideration.</span><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;The hackathon is tentatively scheduled for the last week of&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">February 2025</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">.</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">For further inquiries, please feel free to contact:</span></p>
<ul>
    <li style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;">
        <p><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Tanmay Mann</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, General Secretary (+91 9779084677)</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;">
        <p><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Agrim Goyal</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, Finance Secretary (+91 9876633006)</span></p>
    </li>
    <li style="list-style-type:disc;font-size:12pt;font-family:Arial,sans-serif;">
        <p><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Palakpreet Kaur</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">, Marketing Head (+91 7814211501)</span></p>
    </li>
</ul>
<p><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Sponsorship Proposal:</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;Attached &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span><span style="font-size:12pt;font-family:Arial,sans-serif;"><br></span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Linktree: &nbsp;</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">https://linktr.ee/MLSCTIET</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">Your partnership will be instrumental in making&nbsp;</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">MAKEATHON 7</span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">&nbsp;a resounding success. Thank you for considering this opportunity to inspire innovation and collaboration.</span></p>
<p><span style="font-size:12pt;font-family:Arial,sans-serif;">Sincerely,</span><span style="font-size:12pt;font-family:Arial,sans-serif;"><br></span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;">[YOUR NAME]</span></strong><strong><span style="font-size:12pt;font-family:Arial,sans-serif;"><br></span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Marketing Associate</span><span style="font-size:12pt;font-family:Arial,sans-serif;"><br></span><span style="font-size:12pt;font-family:Arial,sans-serif;">Microsoft Learn Student Chapter</span><strong><span style="font-size:12pt;font-family:Arial,sans-serif;"><br></span></strong><span style="font-size:12pt;font-family:Arial,sans-serif;">Thapar Institute of Engineering and Technology</span></p>`,
    attachment: '',
    companyId: '',
    scheduledTime: '',
    team: '',
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const { state } = useContext(AuthContext);
  const [companyData, setCompanyData] = useState(null);
  const location = useLocation();
  const { data } = location.state || {};
  const id = data?.id || 4242;

  const attachmentTypes = [
    { value: 'tc', label: 'Technical Sponsorship' },
    { value: 'cm', label: 'Media & Community Sponsorship' },
    { value: 'fb', label: 'Food & Beverage Sponsorship' },
    { value: 'fa', label: 'Fashion & Apparel Sponsorship' }
  ];

  const team_name = [
    { value: 'team_1', label: 'Team 1' },
    { value: 'team_2', label: 'Team 2' },
    { value: 'team_3', label: 'Team 3' },
    { value: 'team_4', label: 'Team 4' },
    { value: 'team_5', label: 'Team 5' }
  ];

  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [scheduledTime, setScheduledTime] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  useEffect(() => {
    axios
      .get(`https://backend.marketing.mlsctiet.com/api/companies/get/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          const company = res.data;
          setCompanyData(company);
          setEmailData((prev) => ({
            ...prev,
            body: prev.body.replaceAll("[COMPANY NAME]", company.company_name),
            companyId: company.id
          }));
        } else {
          console.error("Failed to fetch company data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });

    axios
      .get(`https://backend.marketing.mlsctiet.com/api/users/getUser`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          const user = res.data;
          setEmailData((prev) => ({
            ...prev,
            body: prev.body.replaceAll("[YOUR NAME]", `${user.first_name} ${user.last_name}`),
          }));
        } else {
          console.error("Failed to fetch user data.");
        }
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });

    const savedCoordinators = Cookies.get('coordinators');
    if (savedCoordinators) {
      setEmailData((prev) => ({
        ...prev,
        coordinators: savedCoordinators,
      }));
    }

    const savedSubject = Cookies.get('subject');
    if (savedSubject) {
      setEmailData((prev) => ({
        ...prev,
        subject: savedSubject,
      }));
    }

    const savedTeamName = Cookies.get('team');
    if (savedTeamName) {
      setEmailData((prev) => ({
        ...prev,
        team: savedTeamName,
      }));
    }
  }, [state.token]);



  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prev) => ({
      ...prev,
      [name]: name === "coordinators" ? value.replace(/\s/g, "") : value,
    }));
    if (name === "coordinators") {
      Cookies.set('coordinators', value.replace(/\s/g, ""), { expires: 7 });
    } else if (name === "subject") {
      Cookies.set('subject', value, { expires: 7 });
    } else if (name === "team") {
      Cookies.set('team', value, { expires: 7 });
    }
  };

  const handleEditorChange = (content) => {
    setEmailData((prev) => ({
      ...prev,
      body: content,
    }));
  };

  const handleScheduledTimeChange = (newValue) => {
    if (newValue) {
      const formattedTime = format(new Date(newValue), "yyyy-MM-dd'T'HH:mm:ssXXX", { timeZone: 'Asia/Kolkata' });
      setScheduledTime(newValue);
      setEmailData(prev => ({
        ...prev,
        scheduledTime: formattedTime
      }));
    }
  };

  const handleSend = () => {
    setIsLoading(true);
    const emails = emailData.coordinators.split(/;|,/).map((email) => email.trim());
    const invalidEmails = emails.filter((email) => !/\S+@\S+\.\S+/.test(email));

    if (invalidEmails.length) {
      setSnackbar({ open: true, message: `Invalid Emails: ${invalidEmails.join(', ')}`, severity: 'error' });
      setIsLoading(false);
      return;
    }

    const requestData = {
      company_id: emailData.companyId,
      subject: emailData.subject,
      message: emailData.body,
      cc: emails,
      team_name: emailData.team,
      attachment: emailData.attachment
    };

    axios
      .post("https://backend.marketing.mlsctiet.com/api/companies/send_mail", requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          handleCloseModal();
          setSnackbar({ open: true, message: "Email sent successfully!", severity: 'success' });
        } else {
          setSnackbar({ open: true, message: "Failed to send email.", severity: 'error' });
        }
      })
      .catch((err) => {
        console.error("Error sending email:", err);
        setSnackbar({ open: true, message: "Error occurred while sending email.", severity: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSchedule = () => {
    setIsLoading(true);
    const emails = emailData.coordinators.split(';').map((email) => email.trim());
    const invalidEmails = emails.filter((email) => !/\S+@\S+\.\S+/.test(email));

    if (invalidEmails.length) {
      setSnackbar({ open: true, message: `Invalid Emails: ${invalidEmails.join(', ')}`, severity: 'error' });
      setIsLoading(false);
      return;
    }

    if (!emailData.scheduledTime) {
      setSnackbar({ open: true, message: "Please select a valid date and time.", severity: 'error' });
      setIsLoading(false);
      return;
    }

    const requestData = {
      scheduled_time: emailData.scheduledTime,
      subject: emailData.subject,
      body: emailData.body,
      company_id: emailData.companyId,
      attachment: emailData.attachment,
      message: emailData.body,
      cc_list: emails,
      team_name: emailData.team,
      status: 'pending',
    };

    console.log(requestData);

    axios
      .post("https://backend.marketing.mlsctiet.com/api/companies/schedule_mail", requestData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + state.token,
        },
      })
      .then((res) => {
        if (res.status < 400) {
          handleCloseScheduleModal();
          setSnackbar({
            open: true,
            message: `Email scheduled for ${new Date(emailData.scheduledTime).toLocaleString()}.`,
            severity: 'info'
          });
        } else {
          setSnackbar({ open: true, message: "Failed to schedule email.", severity: 'error' });
        }
      })
      .catch((err) => {
        console.error("Error scheduling email:", err);
        setSnackbar({ open: true, message: "Error occurred while scheduling email.", severity: 'error' });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  const handleOpenScheduleModal = () => setScheduleModalOpen(true);
  const handleCloseScheduleModal = () => setScheduleModalOpen(false);
  const handleCloseSnackbar = () => setSnackbar({ ...snackbar, open: false });

  return (
    <>
      <Navbar name="Hub" abbvr="Email" />
      <Box sx={{ padding: { xs: 2, sm: 4, md: 8 } }}>
        <Typography variant="h4" gutterBottom>
          Email Form
        </Typography>
        <Box component="form" sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="attachment-type-label">Team Name</InputLabel>
            <Select
              labelId="team-type-label"
              name="team"
              value={emailData.team}
              label="Choose your team"
              onChange={handleChange}
              required
            >
              {team_name.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Subject"
            name="subject"
            value={emailData.subject}
            onChange={handleChange}
            fullWidth
            required
            InputProps={{
              sx: {
                fontSize: {
                  xs: '0.65rem',
                  sm: '1rem',
                  md: '1.25rem',
                },
              },
            }}
          />

          <TextField
            label="Your Email and Coordinator's Email (separate with a semicolon or comma)"
            name="coordinators"
            value={emailData.coordinators}
            onChange={handleChange}
            fullWidth
            InputProps={{
              sx: {
                fontSize: {
                  xs: '0.65rem',
                  sm: '1rem',
                  md: '1.25rem',
                },
              },
            }}
          />
          <ReactQuill
            value={emailData.body}
            onChange={handleEditorChange}
            modules={{
              toolbar: [
                [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
                [{ size: [] }],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' },
                { 'indent': '-1' }, { 'indent': '+1' }],
                ['link', 'image'],
                ['clean']
              ],
            }}
            formats={[
              'header', 'font', 'size',
              'bold', 'italic', 'underline', 'strike', 'blockquote',
              'list', 'bullet', 'indent',
              'link', 'image'
            ]}
          />
          <FormControl fullWidth>
            <InputLabel id="attachment-type-label">Proposal Type</InputLabel>
            <Select
              labelId="attachment-type-label"
              name="attachment"
              value={emailData.attachment}
              label="Proposal Type"
              onChange={handleChange}
              required
            >
              {attachmentTypes.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenModal}
            disabled={!emailData.attachment}
            sx={{ mt: 2, alignSelf: "center" }}
          >
            Send Email
          </Button>
          <Button
            variant="outlined"
            onClick={handleOpenScheduleModal}
            disabled={!emailData.attachment}
            sx={{ mt: 2, alignSelf: "center" }}
          >
            Schedule Email
          </Button>
        </Box>
      </Box>

      <Dialog
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            padding: 2,
            borderRadius: 2,
            backgroundColor: "#000000",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)"
          }
        }}
      >
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.4rem", color: "#ffffff", alignSelf: "center" }}>
          Confirm Email Sending
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ fontSize: "1rem", color: "#ffffff", marginBottom: 2 }}>
            Are you sure you want to send this email to this Company?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
          <Button onClick={handleCloseModal} variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSend} variant="contained" disabled={isLoading}>
            {isLoading ? "Sending..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isScheduleModalOpen} onClose={handleCloseScheduleModal}>
        <DialogTitle sx={{ fontWeight: "bold", fontSize: "1.4rem", color: "#ffffff", alignSelf: "center" }}>
          Schedule Email
        </DialogTitle>
        <DialogContent sx={{ fontSize: "1rem", color: "#ffffff", margin: 1, overflowX: 'hidden' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label="Select Date and Time"
              value={scheduledTime}
              onChange={handleScheduledTimeChange}
              views={['year', 'month', 'day', 'hours']}
              renderInput={(props) => (
                <TextField
                  sx={{ fontSize: "1rem", color: "#ffffff", margin: 1 }}
                  {...props}
                  fullWidth
                  inputProps={{ ...props.inputProps, readOnly: true }}
                />
              )}
            />
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", gap: 2 }}>
          <Button onClick={handleCloseScheduleModal} variant="outlined" disabled={isLoading}>
            Cancel
          </Button>
          <Button onClick={handleSchedule} variant="contained" disabled={isLoading}>
            {isLoading ? "Scheduling..." : "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
