import React, { useContext, useState } from "react";
import Navbar from "./Navbar";
import dayjs from "dayjs";
import styles from "./styles/Add.module.css";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import { AuthContext } from "../App";
import { CircularProgress } from "@mui/material";
import DoneLottie from "../assets/done-lottie.json";
import Lottie from "lottie-react";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AddCompany = () => {
  const { state } = useContext(AuthContext);

  const [value, setValue] = React.useState(dayjs(Date.now()));
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);
  const [companyNameCheck, setCompanyNameCheck] = React.useState("");
  const [notexists, setExists] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [lottie, setLottie] = useState(false);
  const [snackbarMessage, setMessage] = useState({
    type: "success",
    message: "",
    open: false,
  }); //for user feedback

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const checkCompany = async () => {
    setCheckLoading(true);
    await axios
      .post(
        "https://backend.marketing.mlsctiet.com/api/companies/verify",
        {
          company_name: companyNameCheck,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
        }
      )
      .then((res) => {
        setCheckLoading(false);
        console.log(res);
        if (res.status < 400) {
          if (!res.data.duplicate) {
            setMessage({
              type: "success",
              message: "Company Doesn't Exist",
              open: true,
            });
            setExists(true);
          } else {
            setMessage({
              type: "error",
              message: "Company Already Exists",
              open: true,
            });
          }
        } else {
          setMessage({
            type: "error",
            message: "Something Went Wrong",
            open: true,
          });
        }
      })
      .catch((res) => {
        setCheckLoading(false);
        console.log(res);
        setMessage({
          type: "error",
          message: "Something Went Wrong",
          open: true,
        });
      });
    setTimeout(() => {
      setMessage({
        type: "success",
        message: "",
        open: false,
      });
      setExists(false);
    }, 5000);
  };

  const addCompany = (e) => {
    e.preventDefault();
    setAddLoading(true);
    const form = document.getElementById("addCompanyForm"); //very very bad practice
    // console.dir(e.target);
    const payload = {
      company_name: e.target[0].value,
      poc_name: e.target[2].value,
      email_poc: e.target[4].value,
      mob_no_poc: e.target[6].value,
      social_media_contacts: e.target[8].value,
      website: e.target[10].value,
      notes: e.target[12].value,
      contacted_at: dayjs(e.target[14].value).format('YYYY-MM-DDTHH:mm:ssZ'),
    };
    axios
      .post(
        "https://backend.marketing.mlsctiet.com/api/companies/create",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + state.token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setAddLoading(false);
        if (res.status < 400) {
          setMessage({
            type: "success",
            message: "Company Added Successfully",
            open: true,
          });
          form.reset();
          setLottie(true);
        } else {
          setMessage({
            type: "error",
            message: "Something Went Wrong",
            open: true,
          });
        }
      })
      .catch((e) => {
        console.log(e);
        setAddLoading(false);
        setMessage({
          type: "error",
          message: "Something Went Wrong/Check Entries",
          open: true,
        });
      });
    setTimeout(() => {
      setMessage({
        type: "success",
        message: "",
        open: false,
      });
      setLottie(false);
    }, 5000);
  };

  return (
    <>
      <Navbar name="Company" abbvr="Add" />
      <div className={styles.con}>
        <div className={styles.right}>
          <div className={styles.rightContainer}>
            <div className={styles.rightHeading}>
              Check if the <span className={styles.yellowtext}>Company</span>{" "}
              Exists{" "}
            </div>
            <div className={styles.checkContainer}>
              <label htmlFor="name" className={styles.label}>
                Name of Company :{" "}
                <TextField
                  id="name"
                  label="ML Lassi"
                  variant="outlined"
                  className={styles.inputform}
                  onChange={(e) => setCompanyNameCheck(e.target.value)}
                />
              </label>
              <Button
                variant="contained"
                sx={{ width: 200 }}
                className={styles.lastlabel}
                onClick={checkCompany}
              >
                {checkLoading ? (
                  <CircularProgress color="inherit" />
                ) : notexists ? (
                  <Lottie
                    animationData={DoneLottie}
                    loop={true}
                    style={{ width: "50px" }}
                  />
                ) : (
                  "Check"
                )}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.formContainer}>
          <div className={styles.rightHeading}>
            Add a New <span className={styles.yellowtext}>Company</span>{" "}
          </div>
          <form
            className={styles.form}
            id="addCompanyForm"
            onSubmit={addCompany}
          >
            <div className={styles.formElementContainer}>
              <label htmlFor="name" className={styles.label}>
                Name of Company{" "}
              </label>
              <TextField
                id="name"
                label="Company Name"
                variant="outlined"
                className={styles.inputform}
                required
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="namePOC" className={styles.label}>
                Name of Person
              </label>
              <TextField
                id="emailPOC"
                label="POC"
                variant="outlined"
                className={styles.inputform}
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="emailPOC" className={styles.label}>
                Email of Company/POC
              </label>
              <TextField
                id="emailPOC"
                label="Email"
                variant="outlined"
                className={styles.inputform}
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="mnumber" className={styles.label}>
                Mobile Number of POC
              </label>
              <TextField
                id="mnumber"
                label="Mobile Number"
                variant="outlined"
                className={styles.inputform}
                placeholder="phone number else n/a"
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="socials" className={styles.label}>
                Socials
              </label>

              <TextField
                id="socials"
                label="instagram, twitter, facebook"
                variant="outlined"
                placeholder="url1, url2, url3"
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="website" className={styles.label}>
                Website/Page
              </label>
              <TextField
                variant="outlined"
                id="website"
                label="Website/Page"
                placeholder="https://..."
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="notes" className={styles.label}>
                Notes
              </label>
              <TextField
                variant="outlined"
                id="notes"
                label="Notes"
                placeholder="Any notes which you like to add?"
              />
            </div>
            <div className={styles.formElementContainer}>
              <label htmlFor="approachedon" className={styles.label}>
                Last approached on{" "}
              </label>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Last Contacted"
                  inputFormat="YYYY-MM-DD"
                  value={value}
                  onChange={handleChange}
                  renderInput={(params) => <TextField {...params} />}
                  className={styles.inputform}
                />
              </LocalizationProvider>
            </div>
            <button
              type="submit"
              className={styles.lastlabel}
              styles={{ textAlign: "center" }}
            >
              {addLoading ? (
                <CircularProgress color="inherit" />
              ) : lottie ? (
                <Lottie
                  animationData={DoneLottie}
                  loop={true}
                  style={{ width: "50px" }}
                />
              ) : (
                "Add Company"
              )}
            </button>
          </form>
        </div>
      </div>
      <Snackbar open={snackbarMessage.open}>
        <Alert severity={snackbarMessage.type}>{snackbarMessage.message}</Alert>
      </Snackbar>
    </>
  );
};

export default AddCompany;
